import { isPlatformBrowser } from '@angular/common'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'

import { Observable } from 'rxjs'

import { IUiFacade, UI_FACADE } from '~/core/features'

import { ConfigService } from '../services'

@Injectable()
export class BaseApiInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/^\.?\/?assets.*/.test(req.url)) {
      return next.handle(req)
    }

    const apiUrl = this.configService.config.apiUrl
    const ssrApiUrl = this.configService.config.ssrApiUrl

    const platformApiUrl = isPlatformBrowser(this.platformId) ? apiUrl : ssrApiUrl

    // if (isPlatformBrowser(this.platformId)) {
    //   console.log('CLIENT:`', `${apiUrl}/${req.url}`)
    // } else {
    //   console.log('SERVER:`', `${apiUrl}/${req.url}`)
    // }

    const reqClone = req.clone({
      url: `${platformApiUrl}/${req.url}`,
    })
    return next.handle(reqClone)
  }
}
