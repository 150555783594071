import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http'
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core'
import { provideClientHydration, withEventReplay, withIncrementalHydration } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from '@angular/router'

import { APP_ROUTES } from '../app.routes'

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
}

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig)

export const appInitLibsConfig: ApplicationConfig = {
  providers: [
    // provideZoneChangeDetection({ eventCoalescing: true }),
    provideExperimentalZonelessChangeDetection(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(
      APP_ROUTES,
      inMemoryScrollingFeature
      // withDebugTracing()
    ),

    provideAnimations(),
    provideClientHydration(withEventReplay(), withIncrementalHydration()),
  ],
}
