import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { ApplicationConfig, inject, provideAppInitializer, TransferState } from '@angular/core'

import { BaseApiInterceptor } from '~/core/interceptors'
import { ConfigService } from '~/core/services'

import { configInitializer } from './utils/core'

export const appCoreConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => configInitializer(inject(TransferState), inject(ConfigService))),

    { provide: HTTP_INTERCEPTORS, useClass: BaseApiInterceptor, multi: true },
  ],
}
