import { Inject, Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { IUserFacade, USER_FACADE } from '../features'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(USER_FACADE) private userFacade: IUserFacade,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.userFacade.user$.pipe(
      map(user => {
        if (user === null) {
          this.router.navigateByUrl('/')
          return false
        }

        return true
      })
    )
  }
}
