import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { ApplicationConfig, mergeApplicationConfig, TransferState } from '@angular/core'

import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular'
import { provideTranslateService, TranslateLoader } from '@ngx-translate/core'
import { provideAngularSvgIcon, SvgLoader } from 'angular-svg-icon'

import { AUTH0_CONFIG } from '~env/environment'

import { appCoreConfig } from './configs/app.config.core'
import { appInitLibsConfig } from './configs/app.config.init-libs'
import { SvgBrowserLoader } from './configs/utils/svg/svg-browser-loader.class'
import { TranslateBrowserLoader } from './configs/utils/translate/translate-browser-loader.class'
import { APP_DEFAULT_LANGUAGE } from './core/constants'
import { appStoreConfig } from './core/features'

const svgLoaderFactory = (http: HttpClient, transferState: TransferState): SvgBrowserLoader => {
  return new SvgBrowserLoader(http, transferState)
}

const translateLoaderFactory = (http: HttpClient): TranslateBrowserLoader => {
  return new TranslateBrowserLoader(http, './assets/i18n/', '.json')
}

const clientConfig: ApplicationConfig = {
  providers: [
    // provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([authHttpInterceptorFn])),

    provideAngularSvgIcon({
      loader: {
        provide: SvgLoader,
        useFactory: svgLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),

    // { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    // provideAuth0({
    //   ...ENV.auth,
    //   authorizationParams: {
    //     ...ENV.auth.authorizationParams,
    //     redirect_uri: window.location.origin,
    //   },
    //   httpInterceptor: {
    //     ...AUTH0_INTERCEPTOR,
    //   },
    // }),

    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },

    provideAuth0({
      ...AUTH0_CONFIG,
      authorizationParams: {
        ...AUTH0_CONFIG.authorizationParams,
        redirectUri: window.location.origin,
        redirect_uri: window.location.origin,
      },
    }),

    // {
    //   provide: provideAuth0,
    //   useValue: provideAuth0({
    //     ...ENV.auth,
    //     authorizationParams: {
    //       ...ENV.auth.authorizationParams,
    //       redirectUri: window.location.origin,
    //       redirect_uri: window.location.origin,
    //     },
    //     httpInterceptor: {
    //       ...ENV.httpInterceptor,
    //     },
    //   }),
    // },

    // {
    //   provide: provideAuth0,
    //   useFactory: () => {
    //     return provideAuth0({
    //       clientId: '',
    //       domain: '',
    //     }),
    //   }
    // },

    provideTranslateService({
      defaultLanguage: APP_DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
  ],
}

export const config = mergeApplicationConfig(
  appInitLibsConfig,
  appCoreConfig,
  clientConfig,
  appStoreConfig
  // appAuth0Config
)
