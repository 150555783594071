import { TransferState } from '@angular/core'

import { ENV_STATE_KEY } from '~/core/constants'
import { IEnvState } from '~/core/models'
import { ConfigService } from '~/core/services'
import { DEFAULT_ENV } from '~env/environment'

// INFO: needed to set 'apiUrl' and 'oAuthConfig.issuer' fields in environment.* file,
// data receive via TransferState service, that have been sended by the server
export const configInitializer = (transferState: TransferState, configService: ConfigService): void => {
  const envVars = transferState.get<IEnvState>(ENV_STATE_KEY, DEFAULT_ENV)
  configService.setEnvState(envVars)
}
