import { Routes } from '@angular/router'

import { AdminGuard, AuthGuard, UserGuard } from '~/core/guards'
import { FORECAST_LIST_PATH, NOT_FOUND_PATH, SIGN_IN_PATH } from '~const/index'

import { GUARD_SECTION_DATA_NAME } from './core/constants'
import { EGuardSectionName } from './core/models'
import { aggregationAnalysisQuarterDataResolver } from './pages/aggregation-analysis/aggregation-analysis.resolver'
import { companyForecastResolver } from './pages/company/company.resolver'
import { performanceQuarterListResolver } from './pages/performance/performance.resolver'
import { quarterlyDashboardQuarterDataResolver } from './pages/quarterly-dashboard/quarterly-dashboard.resolver'

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/welcome').then(c => c.WelcomeComponent),
    canActivate: [UserGuard],
  },

  {
    path: SIGN_IN_PATH,
    loadComponent: () => import('./pages/sign-in').then(c => c.SignInComponent),
    canActivate: [UserGuard],
  },

  {
    path: FORECAST_LIST_PATH,
    loadChildren: () => import('./pages/dashboard/dashboard.routes').then(r => r.DASHBOARD_ROUTES),
    canActivate: [AuthGuard],
    data: { [GUARD_SECTION_DATA_NAME]: EGuardSectionName.FORECAST_TABLE },
  },

  { path: 'forecast', redirectTo: `/${FORECAST_LIST_PATH}` },

  {
    path: 'performance',
    loadChildren: () => import('./pages/performance/performance.routes').then(r => r.PERFORMANCE_ROUTES),
    resolve: { resolve: performanceQuarterListResolver },
  },

  {
    path: 'quarterly-dashboard',
    loadChildren: () =>
      import('./pages/quarterly-dashboard/quarterly-dashboard.routes').then(r => r.QUARTERLY_DASHBOARD_ROUTES),
    resolve: { resolve: quarterlyDashboardQuarterDataResolver },
    canActivate: [AuthGuard],
  },

  {
    path: 'aggregation-analysis',
    loadChildren: () =>
      import('./pages/aggregation-analysis/aggregation-analysis.routes').then(r => r.AGGREGATION_ANALYSIS_ROUTES),
    resolve: { resolve: aggregationAnalysisQuarterDataResolver },
    canActivate: [AuthGuard],
  },

  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.routes').then(r => r.USER_ROUTES),
    canActivate: [AuthGuard],
  },

  {
    path: NOT_FOUND_PATH,
    loadComponent: () => import('./pages/not-found/not-found.component').then(c => c.NotFoundComponent),
  },

  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.routes').then(r => r.ADMIN_ROUTES),
    canActivate: [AdminGuard],
  },

  {
    path: 'app-theme',
    loadComponent: () => import('./pages/app-theme/app-theme.component').then(c => c.AppThemeComponent),
  },

  {
    path: ':ticker',
    loadChildren: () => import('./pages/company/company.routes').then(r => r.COMPANY_ROUTES),
    resolve: { resolve: companyForecastResolver },
    data: { [GUARD_SECTION_DATA_NAME]: EGuardSectionName.COMPANY_PAGE },
  },

  { path: '**', redirectTo: `/${NOT_FOUND_PATH}` },
]
